// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
let environment;
if (process.env.NEXT_PUBLIC_PRODUCTION === "false") {
  environment = "development";
} else {
  environment = "prod";
}

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://50f7b76b43744ba79f96ee5e18665f42@o1042705.ingest.sentry.io/6011807",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  environment,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
